<template>
  <div id="Catalog">
    <div class="modal-backdrop" v-if="showDetailModal" @click="showDetailModal = false">
    </div>
    <div class="detail-modal" v-if="showDetailModal">
      <div class="close-modal-button" @click="showDetailModal = false">x</div>
      <div class="container">
        <div class="detail-modal-content">
          <ProductDetailModal :productId=selectedItemId />
        </div>
      </div>
    </div>
    
    
    <div class="main-content main-content-product left-sidebar">
      <div class="container" v-if="typeof catalog.products !== 'undefined'" >
        <div class="row">
            <div class="sidebar col-lg-2 col-md-3 col-sm-12 col-xs-12">
                <div class="wrapper-sidebar shop-sidebar">
                    <div class="widget woof_Widget">
                        <Filters class="mb-3" />
                        <div class="widget widget-categories">
                          <h3 class="widgettitle">Categorias</h3>
                          <button type="button" class="single_add_to_cart_button btn-block btn-sm button"  @click="toggleAllCategories">{{ tituloboton }}</button>
                        </div>
                          <ul class="list-categories">
                            <li v-for="(category, index) in categsFixed" :key="index" class="margen">
                              <div class="categoryline">
                                <div>
                                  <input :id="'category_'+category.id" type="checkbox" v-model="selected" v-bind:value="category.id">
                                  <label :for="'category_'+category.id" class="label-text">{{category.name}}</label>
                                </div>
                                <button v-if="Array.isArray(category.children) && category.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
                              </div>
                              <ul v-if="Array.isArray(category.children) && category.children.length > 0" class="list-categories widget-categories__item">
                                <li v-for="(subcategory, index) in category.children" :key="index" class="mb-1">
                                  <div class="categoryline paddingleft">
                                    <div>
                                    <input :id="'category_'+subcategory.id" type="checkbox" v-model="selected" v-bind:value="subcategory.id">
                                    <label :for="'category_'+subcategory.id" class="label-text2">{{subcategory.name}}</label>
                                    </div>
                                    <button v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
                                  </div>
                                  <ul v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="list-categories widget-categories__item">
                                    <li v-for="(sucsubcategory, index) in subcategory.children" :key="index">
                                      <div class="categoryline paddingleft2">
                                        <input :id="'category_'+sucsubcategory.id" type="checkbox" v-model="selected" v-bind:value="sucsubcategory.id">
                                        <label :for="'category_'+sucsubcategory.id" class="label-text3">{{sucsubcategory.name}}</label>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>                        
                    </div>
                </div>
            </div>

            <div class="content-area shop-grid-content no-banner col-lg-10 col-md-9 col-sm-12 col-xs-12">
                <div class="site-main">
                  <div class="text-center">
                    <h3>Catálogo "{{catalog.name}}"</h3>
                  </div>
                    <div v-if="catalog.products.length > 0">
                      <ShopSortControl
                        :productsOnPages="itemCount"
                        :defaultSortMethod ="sortMethod"
                        v-on:changeQuantity="updateProductsOnPages($event)"
                        v-on:changeSortMethod="updateSortMethod($event)"
                      />
                        <ul class="row list-products auto-clear equal-container product-grid">
                            <li
                            v-for="product in catalog.products"
                            :key="product.id"
                            class="product-item product-type-variable col-lg-3 col-md-4 col-sm-6 col-xs-6 col-ts-6 style-1"
                            >

                                <ProductCard
                                  :product="product"
                                  :useModal="useModal"
                                  @open-detail-modal="loadProduct" />
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <h3 class="text-center">
                        <strong>No se encontró ningun producto</strong>
                        </h3>
                    </div>
                    <GridPagination :outData=outData v-if="catalog.products.length > 0" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "../components/Product-card";
import Filters from "../components/Sidebar/Filters.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProductDetailModal from "../components/ProductDetailModal.vue";
import axios from 'axios';
import ShopSortControl from "../components/gridProducts/ShopSortControl.vue";
import GridPagination from "../components/gridProductsCatalog/GridPagination.vue";

export default {
  data() {
    return {
      paginate: ["catalog","totalPages"],
      itemCount:8,
      q:'',
      sortMethod: 'abc',
      outData:{
          uid: this.$route.params.uid,
          catID:null,
          q: ''
      },
      showDetailModal: false,
      product: null,
      useModal: true,
      selectedItemId: null,
      tituloboton: "Expandir",
      categsFixed: []
    };
  },
  mounted() {
    this.setPageNumber(1);
    this.setItemCount(8);
    this.$store.dispatch("ecommerce/loadCatalog", this.outData);
  },
  components: {
    ProductCard,
    Filters,
    ProductDetailModal,
    ShopSortControl,
    GridPagination
  },
  computed: {
    ...mapGetters({
      catalog: "ecommerce/catalog",
      searchdata: "ecommerce/searchdata",
    }),
    selected: {
      get() {
        return this.searchdata.filters.category
      },
      set(value) {
        this.setFilterCategories(value);
        this.setPageNumber(1);
        this.outData.catID = value;
        this.$store.dispatch("ecommerce/loadCatalog", this.outData);
      }
    }
  },
  methods: {
    ...mapActions({
      loadCatalog: 'ecommerce/loadCatalog'
    }),
    ...mapMutations({
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER',
      setItemCount: 'ecommerce/SET_ITEM_COUNT',
      setSortMethod: 'ecommerce/SET_SORT_METHOD',
      setFilterCategories: 'ecommerce/SET_FILTER_CATEGORIES',
    }),
    updateProductsOnPages(updatedQuantity) {
      this.setPageNumber(1);
      this.itemCount = updatedQuantity;
      this.setItemCount(this.itemCount);
      this.$store.dispatch("ecommerce/loadCatalog", this.outData);
    },
    updateSortMethod(sortMethod) {
      this.setPageNumber(1);
      this.sortMethod = sortMethod;
      this.setSortMethod(this.sortMethod);
      this.$store.dispatch("ecommerce/loadCatalog", this.outData);
    },
    loadProduct(id){
      this.selectedItemId = id
      var vm= this;
      axios.get('/apiecom/ecommerce/products/getProduct/' + id)
        .then(response => {
          if(response.data.status) {
            vm.product = response.data.data;
            this.showDetailModal = true
            this.$store.dispatch("ecommerce/loadProduct", id);
          }
      })
      .catch(error => {
          alert(error);
      })
    },
    toggleSubMenu(event, forceExpand) {
      const buttonElement = event.currentTarget;
      const liElement = buttonElement.closest("li");
      const ulElement = liElement.querySelector(".widget-categories__item");
      const isExpanded = ulElement.classList.contains("widget-categories__item--open");

      const expand = typeof forceExpand === "boolean" ? forceExpand : !isExpanded;
      ulElement.classList.toggle("widget-categories__item--open", expand);
      buttonElement.classList.toggle("button__open", expand);
    },
    toggleAllCategories() {
      this.shouldExpandAll = !this.shouldExpandAll

      const liElements = document.querySelectorAll(".widget-categories__item");
      const buttonElements = document.querySelectorAll(".widget-categories__expander");
      liElements.forEach((liElement) => {
        liElement.classList.toggle("widget-categories__item--open", this.shouldExpandAll);
      });
      buttonElements.forEach((buttonElement) => {
        buttonElement.classList.toggle("button__open", this.shouldExpandAll);
      });

      this.tituloboton = this.shouldExpandAll ? "Contraer" : "Expandir";

    }
  },
  watch: {
    'catalog.categories' : {
      handler: function (categs) {
        if (typeof categs !== 'undefined' && this.categsFixed.length === 0) {
          this.categsFixed = JSON.parse(JSON.stringify(categs))
        }
      }
    }
  }
};
</script>

<style scoped>
.detail-modal {
  max-width: 95vw;
  height: fit-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1000;

}
.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.678);
  z-index: 999;
}
.detail-modal-content {
  width: 100%;
  height: fit-content;
}
.close-modal-button {
  width: 28px;
  height: 28px;
  color: #555;
  position: fixed;
  font-weight: 700;
  top: 20px;
  right: 20px;
  font-size: 22px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  .close-modal-button {
    opacity: 0.7;
  }
  .close-modal-button:hover {
    opacity: 1;
  }
}
.detail-modal-content {
  padding: 30px;
}
.cat-hijo {
  margin-left: 10px;
}
.cat-padre {
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .detail-modal {
    top: 45%;
  }
  .detail-modal-content {
    overflow: auto;
  }
}
.widget-categories {
  position: relative;
  padding-right: 5px;
}

.categoryline {
  display: grid;
  width: 100%;
  grid-template-columns: 75% 25%;
}
.paddingleft {
  padding-left: 10px;
}
.paddingleft2 {
  padding-left: 20px;
}
.margen {
  margin-bottom: 10px;
}
.widget-categories__item {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.widget-categories__expander {
  margin-top: -2px;
  width: 28px!important;
  height: 28px!important;
  position: relative;
  border: none;
  border-radius: 1.5px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: #494949;
  transition: all 0.2s;
  justify-self: end;
}
.widget-categories__expander:focus,
.widget-categories__expander:hover {
  background: #f2f2f2;
  color: #a6a6a6;
}
.widget-categories__expander:focus {
  outline: none;
}
.widget-categories__expander:after,
.widget-categories__expander:before {
  display: block;
  position: absolute;
  content: '';
  background: currentColor;
}
.widget-categories__expander:before {
  width: 8px;
  height: 2px;
  left: calc(50% - 4px);
  top: calc(50% - 1px);
}
.widget-categories__expander:after {
  width: 2px;
  height: 8px;
  left: calc(50% - 1px);
  top: calc(50% - 4px);
  transition: transform 0.2s;
}
.sidebar .label-text {
  font-size: 16px;
}
.sidebar .label-text2 {
    cursor: pointer;
    font-size: 13px;
    color: #555;
    margin: 0;
    padding-left: 20px;
    position: relative;
    font-weight: normal;
}
.sidebar .label-text3 {
    cursor: pointer;
    font-size: 10px;
    color: #979797;
    margin: 0;
    padding-left: 20px;
    position: relative;
    font-weight: normal;
}
.button__open.widget-categories__expander:after {
  transform: rotate(90deg);
}
.widget-categories__item--open.widget-categories__item {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}
.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
</style>
