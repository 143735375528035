<template>
  <div class="tab-details-product">
    <ul class="tab-link">
      <li class="active">
        <a data-toggle="tab" aria-expanded="true" href="#product-descriptions"
          >Descripción</a
        >
      </li>
      <li>
        <a data-toggle="tab" aria-expanded="true" href="#information"
          >Información</a
        >
      </li>
    </ul>
    <div class="tab-container">
      <div id="product-descriptions" class="tab-panel active">
        <p>
          {{product.description}}
        </p>
      </div>
      <div id="information" class="tab-panel">
        <table class="table table-bordered">
          <tr v-for="group in product.attList" :key="group.id">
            <td>{{group.name}}</td>
            <td>{{buildList(group.attributes)}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>


export default {

  name: "DetailsProductModal",
  props: ['product'],
  methods: {
    buildList(values){
      let list= '';
      values.forEach(function(a){
        list = list + a.name + ' / ';
      });
      return list;
    }
  },

};
</script>

<style scoped>
.tab-details-product ul {
  text-align: center;
}
.tab-details-product {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .tab-container {
    max-height: 200px;
    overflow: auto;
  }
}
</style>
