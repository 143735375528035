<template>
  <div>
    <MainDetailsProductModal :productId=productId />
    <div style="clear: left;"></div>
  </div>
</template>

<script>
import MainDetailsProductModal from "./product/MainDetailsProductModal.vue";

export default {
  name: "Product",
  components: {
    MainDetailsProductModal
  },
  props: ['productId']
};
</script>

<style></style>
