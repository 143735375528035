<template>
  <div class="details-product" v-if="product !== null">
    <div class="details-thumd">
      <DetailsProductSlide :slides="product.images" />
    </div>
    <div class="details-infor">
      <h1 class="product-title">{{ product.name }}</h1>
      <div class="availability" v-if="product.code!==null">
          Codigo # {{product.code}}
      </div>

      <div v-if="configuration.show_stock==1" class="availability">
        Stock:
        <a href="#">{{stockStatus()}}</a>
      </div>

      <template v-if="showPrice">
        <div v-if="configuration.show_pricelists==0" class="price">
            <span>${{ (product.prices!== undefined)?product.prices[0]:'' }}</span>
        </div>
        <div v-else>
            Minoristas: <span class="price">$ {{ (product.prices!== undefined)?product.prices[0]:'' }}</span><br>
            Mayoristas: <span class="price">$ {{ (product.prices!== undefined)?product.prices[1]:'' }}</span><br>
        </div>
        <br v-if="configuration.show_pricelists==1">
      </template>
      <ProductGroupButton />
      <DetailsProductModal :product="product" />
    </div>
  </div>
</template>

<script>
import DetailsProductSlide from "./DetailsProductSlide.vue";
import { mapGetters} from "vuex";
import ProductGroupButton from "./ProductGroupButton";
import DetailsProductModal from "./DetailsProductModal.vue";

export default {
  name: "MainDetailsProductModal",
  props: ['productId'],

  data() {
    return {
      instock:'',
      producto: null,
      item: null
    };
  },

  components: {
    DetailsProductSlide,
    ProductGroupButton,
    DetailsProductModal
  },

  computed: {
    ...mapGetters({
      configuration: "ecommerce/configuration",
      product: "ecommerce/product",
      user: "ecommerce/user"
    }),
    showPrice: {
        get: function () {
            if(this.configuration.show_public_prices){
                return true;
            }else{
                if(this.user!=null){
                    return true;
                }
            }
            return false;
        }
    },
  },
  methods: {
      stockStatus(){
        if(this.$store.state.product.stock>0){
            return 'Disponible';
        }else{
            return 'No disponible'
        }
    },
  },

};
</script>

<style scoped>

.splide__list{ will-change:auto !important; }
.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated { will-change:auto !important; }

.quantity-add-to-cart {
  margin-top: 2em;
}

.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
@media screen and (min-width: 768px) {
  .details-infor {
    height: fit-content;
    overflow: auto;
    padding-top: 10px;
  }
  .details-thumd {
    max-width: 80vh;
  }
}
</style>
